@import '../../sass/index.scss';
@import '../../sass/App.scss';


.gpt3__footer {

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: $color-footer;

  &-heading {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;

    margin: 0 0 2rem;

    @media (max-width: 1050px) {
      flex-direction: column;
      align-items: flex-start;
    }

    h1 {
      @extend .gradient__text;
      flex-basis: 100%;
      font-size: 34px;
      line-height: 45px;

      @media (max-width: 550px) {
        font-size: 28px;
        line-height: 38px;
      }
    }
  }

  &-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    align-items: flex-start;

    &-logo {
      display: flex;
      flex-direction: column;

      img {
        width: 120px;
        margin-bottom: 1rem;
      }
    }

    div {
      width: 200px;
      margin: 1rem 0;
    }

    &-div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;

      h4 {
        font-size: 24px;
        line-height: 30px;
      }

      p {
        font-size: 14px;
        padding: 1rem 1rem 1rem 0;
        cursor: pointer;
        transition: 0.3;

        &:hover {
          color: $color-text;
          padding-left: 0.25rem;
        }
      }
    }
  }

  &-copyright {
    margin-top: 2rem;
    text-align: center;
    width: 100%;
    font-size: 14px;
  }
}

.gpt3__footer.section__padding {
  padding-bottom: 1rem;
}