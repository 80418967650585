@import '../../../sass/index.scss';
@import '../../../sass/App.scss';

$inputHeight: 15px;
$offset: 5px;
$transitionEasing: cubic-bezier(.36, 1, .62, .98);

form {
  position: relative;
}

.form-block {
  position: relative;
  margin: 100px auto 0;
  width: 320px;
  padding: 25px;
  background-color: $color-blog;
  border-radius: 2px;
  box-shadow: 0 0 16px 9px rgba(0, 0, 0, 0.07);
}

.form-block__input-wrapper {

  position: relative;
  margin-top: 20px;
  margin-bottom: 2px;
  transition: all .25s $transitionEasing .3s;

}

.form-group {
  &--signup {
    position: absolute;
    top: 0;
    left: $offset;
    width: 100%;
  }
}

.form-group__input {
  display: block;
  font-size: 15px;
  color: #fff;
  width: 100%;
  box-sizing: border-box;
  font-size: 16px;
  padding: 14px 10px;
  margin-bottom: 15px;
  background: rgba(255, 255, 255, .25);
  border: 1px solid rgba(255, 255, 255, .1);
  border-radius: 2px;

  &:focus {
    outline: none;
    border: 1px solid #fff;
    background: transparent;
  }

}



.full-width {
  width: 100%;
}

.button {
  display: inline-block;
  padding: 15px 12px;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: none;
  border-radius: 2 pz;
  transition: all .2s ease-in-out;
  box-shadow: 0px 0px 13px 8px rgba(0, 0, 0, 0.1);

  &--primary {
    background: $color-subtext;
    color: #fff;
    font-weight: bold;
  }

  &:hover {
    box-shadow: 0px 0px 18px 15px rgba(0, 0, 0, 0.15);
  }
}

.form-group__validation {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 20px;
  border-radius: 2px;
  padding-left: 5px;

  background-color: #ddd;

  &--signup {
    background-color: transparent;
    margin-bottom: 10px;
  }

  span {
    color: rgb(228, 77, 77);
    font-weight: bold;
    margin: 5px 0;
    font-size: .8rem;
  }

  .xBtn--validation {
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    color: #042c54;
    font-size: 42px;
    cursor: pointer;

    &:hover {
      font-weight: bold;
    }
  }

  .button {
    padding: 2px 0;
    margin-bottom: 5px;
    color: #042c54;
    background-color: transparent;
    text-transform: capitalize;
    box-shadow: none;
  }
}

.form-group__validation__checkbox {
  display: flex;
  align-items: center;
  margin: 10px 0;
  // letter-spacing: 1px;

  input {
    transform: scale(1.2);
    margin-right: 20px;
  }

  a {
    margin-left: 5px;
    color: rgb(228, 77, 77);
    text-decoration: underline;
  }
}

.form-group__validation--server {
  display: flex;
  position: absolute;
  top: 0;
  left: -2px;
  right: -2px;
  bottom: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 2px;

  padding: 0 1rem;


  background-color: #042c54;

  span {
    color: rgb(228, 77, 77);
    font-weight: bold;
    margin: 20px 0;
    font-size: 1rem;
    text-align: center;
  }


}