@import '../../sass/index.scss';
@import '../../sass/App.scss';

.gpt3__blog {
  display: flex;
  flex-direction: column;

  &-heading {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;

    margin-bottom: 2rem;

    @media (max-width: 1050px) {
      flex-direction: column;
      align-items: flex-start;
    }

    h1 {
      @extend .gradient__text;
      flex-basis: 100%;
      font-size: 34px;
      line-height: 45px;

      @media (max-width: 550px) {
        font-size: 28px;
        line-height: 38px;
      }
    }
  }

  &-container {
    display: flex;

    @media (max-width:990px) {
      flex-direction: column;
    }

    .groupA {
      flex: 0.75;
      margin-right: 2rem;


      @media (max-width:990px) {
        margin-right: 0;
        margin-bottom: 2rem;
      }

      @media (max-width:650px) {
        flex-basis: 100%;
      }
    }

    .groupB {
      flex: 1;
      display: grid;
      overflow: hidden;

      grid-template-columns: repeat(2, 1fr);
      grid-gap: 2rem;

      @media (max-width:650px) {
        display: flex;
        flex-wrap: wrap;
        flex-basis: 100%;
      }
    }
  }
}