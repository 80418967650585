@import '../../sass/index.scss';
@import '../../sass/App.scss';

.feature__component {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;

  margin: 1rem 1.5rem 1rem 0;

  .container-title {
    flex: 1;
    width: 180px;
    margin-right: 2rem;
    margin-bottom: 1.2rem;

    h1 {
      font-weight: 800;
      font-size: 20px;
      line-height: 24px;
      color: #fff;

      @media(max-width: 550px) {
        font-size: 16px;
        line-height: 22px;
      }
    }

    div {
      width: 38px;
      height: 3px;
      background: $gradient-bar;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
      margin-bottom: 0.5rem;
    }
  }

  .container-text {
    flex: 2;
    max-width: 390px;
    display: flex;

    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;

      color: $color-text;

      @media(max-width: 550px) {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}