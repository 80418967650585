@import '../../sass/index.scss';
@import '../../sass/App.scss';

.gpt3__header {
  display: flex;
  padding-top: 8rem;

  &-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    h1 {
      @extend .gradient__text;
      font-family: $font-family;
      font-weight: 800;
      font-size: 62px;
      line-height: 75px;
      letter-spacing: -0.04em;
    }

    p {
      font-family: $font-family;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 27px;
      color: $color-text;

      margin-top: 1.5rem;
    }

    &__input {
      width: 100%;
      margin: 2rem 0 1rem;

      display: flex;

      input {
        flex: 2;
        width: 100%;
        min-height: 50px;
        font-family: $font-family;
        font-size: 20px;
        line-height: 27px;
        background-color: $color-footer;
        border: 2px solid $color-footer;
        padding: 0 1rem;
        outline: none;
        color: #fff;
        border-radius: 5px 0px 0px 5px;
      }

      button {
        background-color: #ff4820;
        border-radius: 5px 0px 0px 5px;
        font-family: $font-family;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 27px;
        color: #fff;
        cursor: pointer;
        outline: none;
        padding: 0 1rem;
      }
    }

    &__people {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      margin-top: 2rem;

      img {
        width: 180px;
        height: 38px;
      }

      p {
        margin: 0 0 0 1rem;
        font-family: $font-family;
        font-size: 12px;
        line-height: 38px;
        color: #fff;
        text-align: center;

      }
    }

  }

  &-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

@media screen and(max-width:1050px) {
  .gpt3__header {
    flex-direction: column;

    &-content {
      margin: 0 0 3rem;
    }
  }
}

@media screen and(max-width:650px) {
  .gpt3__header {
    h1 {
      font-size: 48px;
      line-height: 60px;
    }

    p {
      font-size: 16px;
      line-height: 24px;
    }

    &-content__people {
      flex-direction: column;

      p {
        margin: 0;
      }
    }

    &-content__input input,
    &-content__input button {
      font-size: 16px;
      line-height: 24px;
    }
  }
}

@media screen and(max-width:490px) {
  .gpt3__header {
    h1 {
      font-size: 34px;
      line-height: 46px;
    }

    p {
      font-size: 14px;
      line-height: 18px;
    }

    &-content__input input,
    &-content__input button {
      font-size: 12px;
      line-height: 16px;
    }
  }
}

.gpt3__header.section__padding {
  padding-top: 6rem;
}