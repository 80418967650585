@import '../../sass/index.scss';
@import '../../sass/App.scss';

.gpt3__possibility {
  display: flex;

  @media (max-width: 800px) {
    flex-direction: column;
  }


  &-image {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    margin-right: 2rem;

    @media (max-width:550px) {
      justify-content: center;
    }

    img {
      width: 80%;

      @media (max-width:550px) {
        width: 100%;
      }
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;

    h1 {
      @extend .gradient__text;
      font-weight: 800;
      font-size: 34px;
      line-height: 45px;
      margin: 2rem 0 2rem 0;

      @media (max-width: 550px) {
        font-size: 28px;
        line-height: 38px;
        margin: 1rem 0 1rem 0;
      }
    }

    h4 {
      font-size: 16px;
      line-height: 30px;
      color: $color-text;
      margin-top: 2rem;

      &:last-child {
        color: $color-subtext;
      }
    }

    p {
      font-size: 16px;
      line-height: 24px;

      color: $color-text;

      @media(max-width: 550px) {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}