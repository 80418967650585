$gradient-text: linear-gradient(89.97deg, #AE67FA 1.84%, #F49867 102.67%);
$gradient-bar: linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%);
$color-bg: #040c18;
$color-footer: #031B34;
$color-blog: #042c54;
$color-text: #81AFDD;
$color-subtext: #ff8a71;
$color-button: #ff4820;

$font-family: 'Roboto',
  sans-serif;