@import '../../sass/index.scss';
@import '../../sass/App.scss';


.gpt3__whatgpt3 {
  display: flex;
  flex-direction: column;

  padding: 2rem;
  background: $color-footer;

  /* ff 3.6+ */
  background: -moz-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

  /* safari 5.1+,chrome 10+ */
  background: -webkit-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

  /* opera 11.10+ */
  background: -o-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

  /* ie 10+ */
  background: -ms-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

  /* global 92%+ browsers support */
  background: radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  &-feature {
    display: flex;
  }

  &-heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin: 2rem 0 2rem;

    @media (max-width: 1050px) {
      flex-direction: column;
      align-items: flex-start;
    }

    h1 {
      @extend .gradient__text;
      font-size: 34px;
      line-height: 45px;
      font-family: $font-family;
      max-width: 510px;

      @media (max-width: 550px) {
        font-size: 28px;
        line-height: 38px;
      }
    }

    p {
      font-family: $font-family;
      font-size: 16px;
      line-height: 30px;
      color: $color-subtext;
      cursor: pointer;

      @media (max-width:1050px) {
        margin-top: 1.2rem;
      }
    }
  }

  &-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 2rem;

    .feature__component {
      flex: 1;
      // margin: 1rem 0;
      min-width: 210px;
      flex-direction: column;
    }
  }
}

.gpt3__whatgpt3-feature {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;


  .feature__component {
    flex: 1;
    // margin: 1rem 0;
    min-width: 210px;
    flex-direction: column;

    @media (min-width: 650px) {
      flex-direction: row;
    }
  }
}