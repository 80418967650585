@import '../../sass/index.scss';
@import '../../sass/App.scss';


.blog__article {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background-color: $color-blog;

  min-width: 200px;
  height: 100%;

  overflow: hidden;

  &-image {
    img {
      width: 100%;
    }
  }

  &-content {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    margin: 0 2rem;

    h3 {
      font-size: 18px;
      line-height: 30px;

      margin: 0.5rem 0;

      @media (max-width:550px) {
        margin: 0.5rem 0;
      }
    }

    p {
      font-size: 12px;
      line-height: 24px;
      margin: 1rem 0;

      &:last-child {
        cursor: pointer;
      }

    }

    a {
      padding: 5px 20px;
      background-color: darken($color: $color-blog, $amount: 5%);
      font-size: 12px;
      line-height: 24px;
      margin: 1rem 0;
      margin-bottom: 1rem;
      cursor: pointer;
      font-weight: 600;

      &:hover {
        background-color: lighten($color: $color-blog, $amount: 10%);
      }
    }
  }
}